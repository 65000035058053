var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-tabs",
    { attrs: { "default-active-key": "1" } },
    [
      _c(
        "a-tab-pane",
        { key: "1", attrs: { tab: "Evenements" } },
        [
          _c(
            "a-row",
            {
              staticClass: "mb aliceblue",
              attrs: { align: "middle", justify: "center", type: "flex" }
            },
            [
              _c(
                "a-col",
                {
                  staticStyle: {
                    "margin-bottom": "20px",
                    "margin-top": "20px"
                  },
                  attrs: { md: 20 }
                },
                [
                  _c("a-list", {
                    attrs: {
                      "item-layout": "vertical",
                      size: "large",
                      dataSource: _vm.events
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "renderItem",
                        fn: function(item, index) {
                          return [
                            _c(
                              "a-list-item",
                              { key: "item.titre" },
                              [
                                _c("template", { slot: "extra" }, [
                                  _c("img", {
                                    attrs: {
                                      width: "272",
                                      alt: "logo",
                                      src: item.logo_url[0]
                                    }
                                  })
                                ]),
                                _c(
                                  "a-list-item-meta",
                                  { attrs: { description: item.dt_evenement } },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _c("a", { attrs: { href: item.href } }, [
                                        _vm._v(_vm._s(item.titre))
                                      ])
                                    ]),
                                    _c(
                                      "template",
                                      { slot: "avatar" },
                                      [
                                        _c("a-avatar", [
                                          _vm._v(_vm._s(index + 1))
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.contenu) +
                                    "\n            "
                                )
                              ],
                              2
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-tab-pane",
        { key: "2", attrs: { tab: "Documents" } },
        [
          _c(
            "a-row",
            {
              staticClass: "mb white-bg",
              attrs: {
                align: "middle",
                id: "docs",
                justify: "space-around",
                type: "flex"
              }
            },
            _vm._l(_vm.documents, function(doc) {
              return _c(
                "a-col",
                {
                  key: doc.id,
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { md: 9, lg: 7, xs: 22 }
                },
                [
                  _c(
                    "a-card",
                    { attrs: { hoverable: "" } },
                    [
                      _c("a-card-meta", [
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(doc.titre) +
                                "\n            "
                            )
                          ]
                        ),
                        _c(
                          "span",
                          {
                            attrs: { slot: "description" },
                            slot: "description"
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.date(doc.created_at)))
                            ]),
                            _c("br"),
                            _c(
                              "a-button",
                              {
                                staticStyle: {
                                  "margin-top": "4%",
                                  "text-transform": "uppercase"
                                },
                                attrs: { type: "primary", block: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.downloadDoc(doc.file_url)
                                  }
                                }
                              },
                              [_vm._v("télécharger")]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }