<template>
  <a-tabs default-active-key="1">
    <a-tab-pane key="1" tab="Evenements">
      <a-row align="middle" class="mb aliceblue" justify="center" type="flex">
        <a-col :md="20" style="margin-bottom: 20px; margin-top: 20px">
 
          <a-list item-layout="vertical" size="large" :dataSource="events">
            <template slot="renderItem" slot-scope="item, index">
              <a-list-item key="item.titre">
                <template slot="extra">
                  <!-- MODIFY ME -->
                  <img width="272" alt="logo" :src="item.logo_url[0]" />
                </template>
                <a-list-item-meta :description="item.dt_evenement">
                  <template slot="title">
                    <a :href="item.href">{{ item.titre }}</a>
                  </template>
                  <template slot="avatar">
                    <a-avatar>{{ index + 1 }}</a-avatar>
                  </template>
                </a-list-item-meta>
                {{ item.contenu }}
              </a-list-item>
            </template>
          </a-list>
        </a-col>
      </a-row>
    </a-tab-pane>
    <a-tab-pane key="2" tab="Documents">
      <a-row
        align="middle"
        id="docs"
        class="mb white-bg"
        justify="space-around"
        type="flex"
      >
        <a-col
          v-for="doc in documents"
          :key="doc.id"
          :md="9"
          :lg="7"
          :xs="22"
          style="margin-bottom: 20px"
        >
          <a-card hoverable style="">
            <a-card-meta>
              <span slot="title">
                {{ doc.titre }}
              </span>
              <span slot="description">
                <span>{{ date(doc.created_at) }}</span>
                <br />
                <a-button
                  type="primary"
                  @click="downloadDoc(doc.file_url)"
                  block
                  style="margin-top: 4%; text-transform: uppercase"
                  >télécharger</a-button
                >
              </span>
            </a-card-meta>
          </a-card>
        </a-col>
      </a-row>
    </a-tab-pane>
  </a-tabs>
</template>
  
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "DocumentsIndex",
  created() {
    this.fetchEvents();
    this.fetchDocuments();
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      events: (state) => state.events.events,
      documents: (state) => state.documents.documents,
    }),
  },
  methods: {
    ...mapActions({
      fetchDocuments: "fetchDocuments",
      fetchEvents: "fetchEvents",
    }),
    downloadDoc(fileUrl) {
      this.previewDocument(fileUrl);
    },
  },
};
</script>
